import React from "react";
import resumeFile from "../documents/resume.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
    function calculateAge(birthday) { // birthday is a date
      var ageDifMs = Date.now() - birthday;
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    };
  return (
    <section
      id="about"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
      style={{pageBreakBefore: 'always'}}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            About Me
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Daniel B. Oliveira,</span> a Mechanical Engineer.
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              Mechanical engineer from the Newton Paiva University Center, master's at the Pontifical Catholic University of Minas Gerais. I have solid experience in the area of applied engineering calculus, with an emphasis on numerical methods focused on the areas of structures (finite element methods), flow studies (discrete element methods), pipe flexibility analysis, hydraulic flow studies, and air conditioning.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              My core business is to provide specialized engineering services in industrial piping, granular material flow solutions and structural engineering using numerical methods like the finite element method, the discrete element method, hydraulic simulations, and piping flexibility.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Daniel B. Oliveira
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:daniel@opencae.com.br">daniel@opencae.com.br</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>
                  {
                    calculateAge(new Date('1987-09-17T13:29:15.524486Z'))
                  }
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Belo Horizonte City, Minas Gerais State, Brazil
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>
                  {
                      calculateAge(new Date('2016-01-20T13:29:15.524486Z'))
                  }
                  </span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years of Experiance
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>50</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Executed Projects
                </p>
              </div>
            </div>
            {/*<div className="col-6 col-md-3">
                          <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>38</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Get Awards
                </p>
              </div>
            </div>*/}
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
