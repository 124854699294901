import React from "react";
import resumeFile from "../documents/resume.pdf";

const Skills = ({ classicHeader, darkTheme }) => {
  const skills = [
    {
      name: "Agile & Waterfall Methodologies",
      percent: 100,
    },
    {
      name: "Leadership",
      percent: 100,
    },
    {
      name: "Open-Source Software",
      percent: 100,
    },
    {
      name: "Research Skills",
      percent: 100,
    },
    {
      name: "Conflict Resolution",
      percent: 100,
    },
    {
      name: "Finite Element Method",
      percent: 100,
    },
    {
      name: "Microsoft Excel",
      percent: 100,
    },
    {
      name: "Microsoft Word",
      percent: 100,
    },
    {
      name: "Programming",
      percent: 100,
    },
    {
      name: "Linux",
      percent: 100,
    },
    {
      name: "Pyhton",
      percent: 100,
    },
    {
      name: "C++",
      percent: 100,
    },
    {
      name: "Matlab",
      percent: 100,
    },
    {
      name: "Discret Element Method",
      percent: 100,
    },
    {
      name: "Altair HyperWorks",
      percent: 100,
    },
    {
      name: "Paraview",
      percent: 100,
    },
    {
      name: "LIGGGHTS-PUBLIC",
      percent: 100,
    },
    {
      name: "Libmesh",
      percent: 100,
    },
    {
      name: "Code_aster",
      percent: 100,
    },
    {
      name: "Salome-Meca",
      percent: 100,
    },
    {
      name: "Bentley AutoPipe",
      percent: 100,
    },
    {
      name: "AFT Fathom",
      percent: 100,
    },
    {
      name: "HVAC",
      percent: 100,
    },
    {
      name: "Solidworks",
      percent: 100,
    },
    {
      name: "Autocad",
      percent: 100,
    },
    {
      name: "Latex",
      percent: 100,
    },
    {
      name: "Maple",
      percent: 100,
    },
    {
      name: "Proteus Isis",
      percent: 100,
    },
    {
      name: "Arduino",
      percent: 100,
    },
    {
      name: "Raspberry Pi",
      percent: 100,
    },
  ];

  return (
    <section
      id="skills"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
      style={{pageBreakBefore: 'always'}}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Skills
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Skills
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  {/*<span className="float-end">{skill.percent}%</span>*/}
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
