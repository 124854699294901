import React from "react";

import Slider from "react-slick";

const Publications = ({ classicHeader, darkTheme }) => {
  const publications = [
    {
      name: "Hand Vibration Analysis due to Agricultural Machine Vibration",
      position: "Proceedings of the XLII Ibero-Latin-American Congress on Computational Methods in Engineering and III Pan-American Congress on Computational Mechanics, ABMEC-IACM",
      year: 2021,
      //src: "images/testimonial/client-sm-3.jpg",
      desc: "This study aims to present vibration’s effects on the human hand of agricultural machine drivers, often neglected, which are related to work and cause serious health issues. The chosen equipment does not produce an extreme vibration, such as cement breakers, in order to show that even minor vibration can generate stress and strain on the body. In addition, the continuous exposure to this situation can cause fatigue, unpredictable health issues, performance decrease, nausea, among others. The vibration used in this article was smaller than the ones found in the literature.",
      //rating: 5,
    },
    {
      name: "Proof of Concept of a 3D Printed Prosthetic Foot by Finite Element Analyzes",
      position: "Encontro Nacional de Modelagem Computacional",
      year: 2021,
      //src: "images/testimonial/client-sm-3.jpg",
      desc: "This study aims to propose a poof of concept of a geometry that could be used for a 3D printed prosthetic foot using the thermoplastics PETG, ABS and PLA. This geometry needs to have an adequate displacement that can help people during walking, store and dissipating energy. Using the finite element method it was possible to assess how this material behaves when submitted to a load of approximately 1500 N, which represents the weigh of a 150 kg person standing on one foot. Another important technology that has been used in this study is 3D print as a way to build assistive technology. The results using the falure criteria Tsai-Hill showed that the foot hold this applied weigh if printed using PETG, because of its longitudinal and transverse tensile strength and shear strength. The curvatures that were proposed to help to store energy are not a problem taking into account only structural problems that it could bring, because its stress. This proof of concept passed in the structural test.",
      //rating: 5,
    },
    {
      name: "Computational Analysis of the Operation of a Ore Car Dumper Using the Integration of Numerical Methods of Discrete and Finite Elements and Multibody Dynamics",
      position: "X Congresso Nacional de Engenharia Mecânica",
      year: 2018,
      //src: "images/testimonial/client-sm-3.jpg",
      desc: "The objective of this work was to identify the components of the resulting forces and their variations in the contact between the floor and the car dumper support, these resulting forces were obtained by a numerical analysis of the ore unloading process for the most severe operation condition in that the cohesion of the material was maximal. The numerical analysis was performed using the Multiple Body Dynamics (MBD) integrated to the discrete elements (DEM) and Finite Element (FEM) coupling. The integration of the numerical methods with the dynamics was carried out to assist more accurate studies in relation to the actual condition of the operation of the car dumper. In the integration between the DEM-FEM the contact forces extracted from the granular wall, representing the wagon, of the DEM simulation were imported into the FEM study. In this last one the boundary conditions of the wagon with the dumper clips were defined and the reactions were extracted over time in the four regions of contact of the fixation clips. These reactions were imported into the MBD study and the dynamics of the assembly were observed during the process of rotation of the box over time and variations of the components of the resulting forces were obtained on the contact joint between the floor and the car dumper. The open source software LIGGGHTS-PUBLIC 3.7, code_aster 13.4 and the academic research software of ANSYS Mechanical APDL 19.0 were used for the DEM, FEM and MBD simulations, respectively.",
      //rating: 5,
    },
    {
      name: "Comparison Between Results of Analytical And  Numerical Model of Piping Flexibility During Thermal Expansion",
      position: "Proceedings of the XXXVIII Iberian Latin-American Congress on Computational Methods in Engineering · Nov 5, 2017",
      year: 2017,
      //src: "images/testimonial/client-sm-3.jpg",
      desc: "The industry needs predictability to work on a large scale without complications, only this way you can ensure your productivity. The piping flexibility analysis provides a prediction of future problems and proposes applicable solutions, with the objective of preventing pipes to suffer collapses, that can impact the production process and costs, and provide safety to workers and the environment, while avoid leaks and possible contamination. The aim of this study is analyse the flexibility of industrial piping through the finite elements and photoelasticity methods. For stresses analysis, using a computerized body of proof, it`s possible to find, through finite elements and photoelasticity`s practical project, the values of the stresses and the places where they are being applied. To guarantee that the computerized and practical models are consistent with reality, a mathematical model, already tested and proved, will also be implemented and compared to the others, so there are evidences that all models used are really reliable and can be used in large-scale industrial projects, with complex studies. A comparison of a mathematical model through balanced guided beam, a finite elements model using the software ANSYS® and a photoelasticity of a resin pipe will show that the method with better applicability in industries is the computational, showing trustable stress, reaction and deformation values as well as a detailed visualization of them distribution along the object of study.",
      //rating: 5,
    },
    {
      name: "Development of the DEM-FEM Integration (LIGGGHTS-PUBLIC and code_aster) for the Analysis of Particle Deposition with Cohesion in a Truck Bucket",
      position: "XXXVII Ibero-Latin American Congress on Computational Methods in Engineering · Nov 12, 2016",
      year: 2016,
      //src: "images/testimonial/client-sm-1.jpg",
      desc: "The article discusses the development and integration of Finite Element Methods (FEM) and Discrete Element Method (DEM). The study utilized two open-source software: LIGGGHTS-PUBLIC and code_aster. The object of study was a generic truck bed model, serving as the basis for measuring particle-structure interactions. A one-way coupling approach was employed, where contact forces measured in the DEM simulation were applied as surface forces in the FEM study. The coupling strategy involved tracking the identifiers (ID) of each element between the two studies and applying the load accordingly.",
      //rating: 5,
    },
    {
      name: "Description of the Operation of the Liggghts®-Public Software for the Deposition of Particles with Cohesion Using DEM in a Truck Bucket",
      position: "XXXVII Ibero-Latin American Congress on Computational Methods in Engineering · Nov 11, 2016",
      year: 2016,
      //src: "images/testimonial/client-sm-1.jpg",
      desc: "This article presents the procedure used to simulate the deposition of granular components with cohesion in a bucket model using the Discrete Element Method (MED) and the open-source software LIGGGHTS®-PUBLIC. The model represents the geometry of a bucket designed for transporting ore. The simulations carried out in this study will aid future research in the field of MED simulations using LIGGGHTS software and contribute to refining the results for similar applications.",
      //rating: 5,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="publications"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
      style={{pageBreakBefore: 'always'}}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Publications
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Publications
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {publications.length > 0 &&
            publications.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    {/*<img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                />*/}
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    {value.desc}
                  </p>
                  {/*<span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                    </span>*/}
                    <span className="text-2">
                      {" "}
                      {value.year}
                    </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Publications;
