import React from "react";

const Experience = ({ classicHeader, darkTheme }) => {
  const experienceDetails = [
    {
      yearRange: "2023 - Present",
      title: "Utilities Systems and Piping Leader",
      company: "TRENG Consulting and Technology in Projects",
      place: "Belo Horizonte City, Minas Gerais, Brazil",
      desc: `
      Leader of Utility Systems and Piping disciplines for projects in the mining industry.
      Analyze execution schedules to establish realistic deadlines and efficiently allocate the workforce.
      Develop work plans to define clear and achievable scopes.
      Provide technical support to enhance the quality and efficiency of development teams.
      Extensive participation in technical alignment meetings with customers and internal teams.
      Support the budget team in understanding the necessary requirements for work proposals.
      Verify engineering documents to ensure delivery quality. Conduct selection interviews for hiring candidates.
      Manage third-party involvement in the development of engineering documentation.
      Development and control of quality and productivity metrics.
      `,
    },
    {
      yearRange: "2021 - 2023",
      company: "Brass",
      title: "Utility Systems Engineer",
      place: "Belo Horizonte City, Minas Gerais, Brazil",
      desc: `Development of projects for the Utilities Systems discipline including the production of project documents such as calculation memorials, P&ID, technical specifications for equipment and services, definitions of standards and project criteria, and also a constant planning follow-up. Conducting external and internal technical alignment meetings, as well as project presentations for clients and peers.`,
    },
    {
      yearRange: "2021 - 2021 (7 months)",
      company: "TRENG Consulting and Technology in Projects",
      title: "Utility System Engineer",
      place: "Belo Horizonte City, Minas Gerais, Brazil",
      desc: `On my first stint at Treng I worked on developing technical documents for HVAC discipline projects, performing structural calculations using the finite element method (FEM), and analyzing flow structures using the discrete element method (DEM). I was responsible for the production of project documents, including calculation memorials, technical specifications for equipment and services, and the definition of standards and project criteria. Additionally, I conducted external and internal technical alignment meetings, and delivered project presentations  for clients and peers.`,
    },
    {
      yearRange: "2020 - 2021 (1 year, 1 month)",
      company: "OpenCAE Engineering",
      title: "Mechanical Engineer",
      place: "Belo Horizonte City, Minas Gerais, Brazil",
      desc: `I worked on developing DEM conceptual software for engineering and simulating granular flow systems for mining companies, as well as FEM studies for structural analyses.`,
    },
    {
      yearRange: "2017 - 2020 (3 year, 1 month)",
      company: "Conep Project Consultancy",
      title: "Mechanical Engineer",
      place: "Belo Horizonte City, Minas Gerais, Brazil",
      desc: `I worked on projects involving structural and mechanical systems using the finite element method (FEM).
      I have carried out projects and verified granular material transport systems, using the discrete element method (DEM).
      I implemented design and flexibility calculations for piping and pumping systems.
      I executed a considerable number of hydraulic projects for mining and steel companies.
      `,
    },
    {
      yearRange: "2016 - 2017 (1 year, 1 month)",
      company: "Conep Project Consultancy",
      title: "Technical Seller",
      place: "Belo Horizonte City, Minas Gerais, Brazil",
      desc: `I was responsible for the assembly and preparation of oxy-acetylene welding equipment. I handled prospecting new customers, closed deals, and ensured high-quality customer service and loyalty. I also managed post-sales as well as storage replenishment to maintain adequate inventory levels.`,
    },
  ];

  return (
    <section
      id="experience"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
      style={{pageBreakBefore: 'always'}}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Experience
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Experience
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Experience */}
          <div className="col-md-12">
            {/*<h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>*/}
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.company}
                  </p>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
