import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    DEM: "DEM",
    FEM: "FEM",
    CAD: "CAD",
    ENG: "ENG",
  };

  const projectsData = [
    {
      title: "Flow Batch Scale Simulation - Modified Position",
      projectInfo: `The video shows the simulation a flow batch scale using the discrete element method (DEM). This simulation was performed to define the best possible configuration for the geometry to avoid strutuctural damage on the unloading chamber. In the video it is possible to see that the resulting flow on the unloading chamber are smooth.`,
      client: "VLI Logística",
      technologies: "DEM",
      industry: "Mining",
      date: "January, 2023",
      url: {
        name: "Flow Batch Scale Simulation - Modified Position",
        link: "https://www.youtube.com/embed/-6AuoqDLDNw",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/-6AuoqDLDNw/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-1.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.DEM],
    },
    {
      title: "Conceptual Drawing Machine Project",
      projectInfo: `This is a conceptual project of a drawing machine. The project was based in other projects that I found on internet.`,
      client: "Just a Concept",
      technologies: "Solidworks",
      industry: "Art & Design",
      date: "May, 2023",
      url: {
        name: "Conceptual Drawing Machine Project",
        link: "https://www.youtube.com/embed/P8Jxy2VU4nI",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/P8Jxy2VU4nI/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.CAD],
    },
    {
      title: "What is DEM Calibration?",
      projectInfo: `
        The discrete element method (DEM) is a numerical technique used to simulate the behavior of granular materials such as powders, sand, and rocks. It works by dividing the material into discrete particles that interact with each other through contact forces, and the motion and deformation of each particle are tracked over time.
        DEM calibration refers to the process of determining the values of parameters used in the simulation, such as particle size, particle shape, and material properties, so that the simulation results match the behavior of the real-world system being modeled.
        Calibration is necessary because the values of these parameters are often not known precisely in real-world systems, and slight variations in their values can significantly affect the behavior of the simulated material. By comparing the simulation results to experimental data, the values of the parameters can be adjusted until the simulation accurately predicts the behavior of the real-world system.
        DEM calibration can be a complex and time-consuming process, but it is essential for obtaining reliable and accurate simulation results.`,
      client: "Educational Video",
      technologies: "Liggghts-Public",
      industry: "Mining",
      date: "March, 2023",
      url: {
        name: "What is DEM Calibration?",
        link: "https://www.youtube.com/embed/ucSb6cvRamI",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/ucSb6cvRamI/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.DEM],
    },
    {
      title: "Ship Loader Simulation - Original Geometry",
      projectInfo: `The video shows the simulation a shiploader using the discrete element method (DEM). This simulation was performed to define the best possible configuration for the geometry of the shiploader to avoid material escaping. In the video it is possible to see that the resulting geometry has a good trajectory.`,
      client: "Vale",
      technologies: "Liggghts-Public",
      industry: "Mining",
      date: "February, 2021",
      url: {
        name: "Ship Loader Simulation - Original Geometry",
        link: "https://www.youtube.com/embed/Dq5dDS4E9eg",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/Dq5dDS4E9eg/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.DEM],
    },
    {
      title: "Ship Loader Simulation - Modified Geometry",
      projectInfo: `The video shows the simulation a shiploader using the discrete element method (DEM). This simulation was performed to define the best possible configuration for the geometry of the shiploader to avoid material escaping. In the video it is possible to see that the resulting geometry has a good trajectory.`,
      client: "Vale",
      technologies: "Liggghts-Public",
      industry: "Mining",
      date: "February, 2021",
      url: {
        name: "Ship Loader Simulation - Modified Geometry",
        link: "https://www.youtube.com/embed/lZkM0dBW1HQ",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/lZkM0dBW1HQ/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.DEM],
    },
    {
      title: "Chute Simulation",
      projectInfo: `
      The video shows the simulation of a chute using the discrete element method (DEM). This simulation was performed to define the best possible configuration for the geometry of the chute to avoid material clogging. In the video, it is possible to see that the resulting geometry has no problem.`,
      client: "Anglo American",
      technologies: "Liggghts-Public",
      industry: "Mining",
      date: "November, 2021",
      url: {
        name: "Chute Simulation",
        link: "https://www.youtube.com/embed/swR7R9KuJ4k",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/swR7R9KuJ4k/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.DEM],
    },
    {
      title: "Chute with Reversible Conveyor -  LIGGGHTS-PUBLIC",
      projectInfo: `
      The video shows the simulation of a transfer chute with reversible conveyor using the discrete element method (DEM). This simulation was performed to define the best possible configuration for the geometry of the chute to avoid material clogging. In the video, it is possible to see that the resulting geometry has no flow problems.`,
      client: "Anglo American",
      technologies: "Liggghts-Public",
      industry: "Mining",
      date: "June, 2020",
      url: {
        name: "Chute with Reversible Conveyor -  LIGGGHTS-PUBLIC",
        link: "https://www.youtube.com/embed/MmQC9Oaze5U",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/MmQC9Oaze5U/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.DEM],
    },
    {
      title: "Torsion Simulation of End Wrench in Solver code aster",
      projectInfo: `
      Static linear finite element analysis of a wrench key using code_aster.`,
      client: "Just a Concept",
      technologies: "code_aster",
      industry: "Art & Design",
      date: "September, 2022",
      url: {
        name: "Torsion Simulation of End Wrench in Solver code aster",
        link: "https://www.youtube.com/embed/wW6gWzwbJY4",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/wW6gWzwbJY4/hqdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.FEM],
    },
    {
      title: "2 Chutes System - Original Geometry",
      projectInfo: `The video shows the simulation a system compose of two transfers chute using the discrete element method (DEM). This simulation was performed to define the best possible configuration for the geometry of the transfer chutes to avoid material cloging. In the video it is possible to see that there is not cloging problem the resulting geometry.`,
      client: "Anglo American",
      technologies: "Liggghts-Public",
      industry: "Mining",
      date: "July, 2020",
      url: {
        name: "2 Chutes System - Original Geometry",
        link: "https://www.youtube.com/embed/KZ5MdTDMoHM",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/KZ5MdTDMoHM/hqdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.DEM],
    },
    {
      title: "Rotating System Motion Study of a Bucket-Wheel Excavator",
      projectInfo: `The video shows the motino study of a bucket-wheel excavator using the software Solidworks. This study was performed to show how the system works.`,
      client: "Just a Concept",
      technologies: "Solidworks",
      industry: "Art & Design",
      date: "July, 2020",
      url: {
        name: "Rotating System Motion Study of a Bucket-Wheel Excavator",
        link: "https://www.youtube.com/embed/A_lOLz-S9tg",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/A_lOLz-S9tg/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.CAD],
    },
    {
      title: "Conceptual Floating Solar Power Plant",
      projectInfo: `The video shows a concptual floating solar power plant. The concept was used to understand better a possible configuration of this kind of plant.`,
      client: "Just a Concept",
      technologies: "Solidworks",
      industry: "Art & Design",
      date: "June, 2020",
      url: {
        name: "Conceptual Floating Solar Power Plant",
        link: "https://www.youtube.com/embed/I9O1RSMUNxc",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/I9O1RSMUNxc/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.CAD],
    },
    {
      title: "Oxyfuel cutting",
      projectInfo: `The video shows oxyfuel cutting of a 10mm thick sheet using oxygen and GLP.`,
      client: "Just a Concept",
      technologies: "Oxyfuel",
      industry: "Industrial Gases",
      date: "June, 2017",
      url: {
        name: "Oxyfuel cutting",
        link: "https://www.youtube.com/embed/FX7ZkjCkYPU",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/FX7ZkjCkYPU/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.ENG],
    },
    {
      title: "Railcar Dumper DEM Simulation",
      projectInfo: `The video shows the simulation a railcar dumper using the discrete element method (DEM). This simulation was performedas parte a master tesis.`,
      client: "Vale",
      technologies: "Liggghts-Public",
      industry: "Mining",
      date: "October, 2018",
      url: {
        name: "Railcar Dumper DEM Simulation",
        link: "https://www.youtube.com/embed/1fOyY-sjsdM",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "https://img.youtube.com/vi/1fOyY-sjsdM/maxresdefault.jpg",
      //sliderImages: [
      //  "images/projects/project-2.jpg",
      //  "images/projects/project-5.jpg",
      //],
      categories: [filters.DEM],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
        style={{pageBreakBefore: 'always'}}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              My Work
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
