import React from "react";

const Education = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2016 - 2019",
      title: "Master’s degree",
      place: "Pontifical Catholic University of Minas Gerais",
      desc: "I researched the coupling between the finite element method and the discrete element method using open-source software. I used code_aster software as a finite element structural solver and LIGGGHTS-PUBLIC software as a discrete element solver. My work consisted of performing coupled simulations between the two solvers to analyze the material flow and structural load in car dumpers.",
    },
    {
      yearRange: "2022 - 2023",
      title: "Postgraduate",
      place: "Pontifical Catholic University of Minas Gerais",
      desc: "The course has the objective to training specialists in Project Management following the PMI (Project Management Institute) standard disseminated by the PMBOK, capable of articulating strategic and practical thinking about project management, and highlighting the multidisciplinary character by involving themes and subjects from different areas (human, exact and biological).",
    },
    {
      yearRange: "2010 - 2016",
      title: "Undergraduate",
      place: "Newton Paiva University Center",
      desc: "Mechanical Engineering aims to prepare students so that they can work in the structure of processes in companies — from the design of new machines, equipment and parts for the industry to the inspection of the production line, monitoring of maintenance and carrying out tests with the machinery.",
    },
  ];

  return (
    <section
      id="education"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
      style={{pageBreakBefore: 'always'}}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Education
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Education
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-12">
            {/*<h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>*/}
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
